import { Outlet, Route, Routes } from "react-router-dom";
import NavBar from "../../components/Navbar";
import { OrderProvider } from "../../contexts/order";
import { DetailsOrder } from "./Details";
import { ListOrders } from "./List";
import NewOrder from "./NewOrder";
import ReviewOrder from "./ReviewOrder";
export const Orders = () => {
  return (
    <>
      <OrderProvider>
        <NavBar />
        <Outlet />
        <Routes>
          <Route path="/" element={<ListOrders />} />
          <Route path="new-order" element={<NewOrder />} />
          <Route path="review-order" element={<ReviewOrder />} />
          <Route path="details/:id" element={<DetailsOrder />} />
        </Routes>
      </OrderProvider>
    </>
  );
};
