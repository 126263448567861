import { Container } from "@chakra-ui/react";
import NavBar from "../../components/Navbar";
import UltimosPedidos from "../../components/LastOrders";
import QuickAccess from "../../components/QuickAccess";

function Home() {
  return (
    <>
      <NavBar />
      <Container maxW={"container.xl"} mt={"4"} p={"4"}>
        <UltimosPedidos />
        <QuickAccess />
      </Container>
    </>
  );
}

export default Home;
