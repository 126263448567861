import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Heading, Box, Divider, StatGroup, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import api from "../../services/api.service";
import PedidoStat from "./stat";
function UltimosPedidos() {
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    api.get("client/app/order/lasts").then((res) => setOrders(res.data));
  }, []);
  return (
    <Box mt={"4"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Heading as={"h1"} fontSize={"lg"}>
          Últimos pedidos realizados
        </Heading>
        <Link fontSize={"sm"} as={ReactLink} to="/orders">
          Ver meus pedidos <ArrowForwardIcon />
        </Link>
      </Box>
      <Divider my={"4"} />
      <StatGroup>
        {orders.map((order: any) => (
          <PedidoStat
            key={order.id}
            id={order.id}
            status={order.status}
            amount={order.total}
            date={order.created_at}
          />
        ))}
      </StatGroup>
    </Box>
  );
}

export default UltimosPedidos;
