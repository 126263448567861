import { Table, Thead, Tr, Th, Tbody, Td, Badge } from "@chakra-ui/react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import CurrencyFormat from "react-currency-format";

export const FinancialOpens = (props: any) => {
  return (
    <>
      <Table size={"sm"}>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Total</Th>
            <Th>Pgto.</Th>
            <Th>Status</Th>
            <Th>Data</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.sales.length ? (
            props.sales.map((item: any, index: any) => (
              <Tr key={index}>
                <Td>{item.id}</Td>
                <Td>
                  <CurrencyFormat
                    value={item.total}
                    prefix="R$ "
                    displayType={"text"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                  />
                </Td>
                <Td textTransform={"uppercase"}>{item.payment}</Td>
                <Td textTransform={"uppercase"}>{item.status}</Td>
                <Td>
                  {format(new Date(item.created_at), "d MMMM yyyy H:mm", {
                    locale: ptBR,
                  })}
                </Td>
              </Tr>
            ))
          ) : (
            <></>
          )}
        </Tbody>
      </Table>
      <Badge p={2} mt={4}>
        Total:{" "}
        <CurrencyFormat
          value={props.total}
          prefix="R$ "
          displayType={"text"}
          fixedDecimalScale={true}
          decimalScale={2}
          decimalSeparator={","}
          thousandSeparator={"."}
        />
      </Badge>
    </>
  );
};
