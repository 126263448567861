import { SearchIcon } from "@chakra-ui/icons";
import { Table, Tbody, Td, Th, Thead, Tr, Link } from "@chakra-ui/react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import CurrencyFormat from "react-currency-format";
import { Link as RouterLink } from "react-router-dom";

export const FinancialInvoices = (props: any) => {
  return (
    <Table size={"sm"}>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Total</Th>
          <Th>Referência</Th>
          <Th>Período</Th>
          <Th>Status</Th>
          <Th>Gerada em</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.invoices.length ? (
          props.invoices.map((item: any, index: any) => (
            <Tr key={index}>
              <Td>{item.id}</Td>
              <Td>
                <CurrencyFormat
                  value={item.total}
                  prefix="R$ "
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </Td>
              <Td textTransform={"uppercase"}>{item.reference}</Td>
              <Td>
                {format(new Date(item.start), "d MMMM yyyy", {
                  locale: ptBR,
                })}{" "}
                -{" "}
                {format(new Date(item.end), "d MMMM yyyy", {
                  locale: ptBR,
                })}
              </Td>
              <Td textTransform={"uppercase"}>{item.status}</Td>
              <Td>
                {format(new Date(item.created_at), "d MMMM yyyy H:mm", {
                  locale: ptBR,
                })}
              </Td>
              <Td>
                <Link
                  as={RouterLink}
                  to={`/financial/invoice/details/${item.id}`}
                  color={"blue.400"}
                >
                  <SearchIcon /> ver detalhes
                </Link>
              </Td>
            </Tr>
          ))
        ) : (
          <></>
        )}
      </Tbody>
    </Table>
  );
};
