import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Container,
  Heading,
  Box,
  Divider,
  Spinner,
  StatGroup,
  Link,
  Badge,
} from "@chakra-ui/react";
import { Link as LinkRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import { BreadcrumbPage, LinkBreadCrump } from "../../../components/Breadcrumb";
import { ProductItem } from "../../../components/Product/item";
import { useOrder } from "../../../contexts/order";
import api from "../../../services/api.service";

type Product = {
  id: number;
  label: string;
  measure: string;
  price: number;
};

type SpecialProduct = Product & {
  pivot: {
    price: number;
  };
};

type ResponseProducts = {
  special: SpecialProduct[];
  products: Product[];
};

const NewOrder = () => {
  const items: LinkBreadCrump[] = [
    { label: "Dashboard", path: "/" },
    { label: "Pedidos", path: "/orders" },
    { label: "Novo pedido", path: "/orders/new-order" },
  ];

  const [products, setProducts] = useState<ResponseProducts>();
  const [loading, setLoading] = useState<boolean>(false);

  const order = useOrder();

  useEffect(() => {
    setLoading(true);
    api.get("client/app/products").then((res) => {
      setProducts(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <Container maxW={"container.xl"} mt={"4"} p={"4"}>
      <BreadcrumbPage items={items} />
      {loading ? (
        <Box w={"100%"} m={"8"} textAlign={"center"}>
          <Spinner size={"lg"} label="Buscando produtos..." />
        </Box>
      ) : (
        <Box mt={"8"} mb={20}>
          <Box>
            <Heading as={"h1"} fontSize={"lg"}>
              Produtos disponíveis
            </Heading>
            <Divider my={"4"} />
            <StatGroup>
              {products?.special.map((p: SpecialProduct) => {
                return (
                  <ProductItem
                    key={p.id}
                    id={p.id}
                    label={p.label}
                    measure={p.measure}
                    price={p.pivot.price}
                  />
                );
              })}
            </StatGroup>
          </Box>
          {order.items?.length ? (
            <Box
              bg={"green.500"}
              position={"fixed"}
              color={"white"}
              p={4}
              borderRadius={8}
              bottom={5}
              right={5}
              shadow={"md"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Badge mr={4}>{order.items.length} item(s)</Badge>
                <Link as={LinkRouter} to={"/orders/review-order"}>
                  Revisar pedido <ArrowForwardIcon />
                </Link>
              </Box>
            </Box>
          ) : <></>}
        </Box>
      )}
    </Container>
  );
};

export default NewOrder;
