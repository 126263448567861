import { BellIcon, CheckIcon } from "@chakra-ui/icons";
import {
  Container,
  Box,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BreadcrumbPage, LinkBreadCrump } from "../../components/Breadcrumb";
import api from "../../services/api.service";
import { FinancialInvoices } from "./Invoices";
import { FinancialOpens } from "./Opens";

type FinancialResponse = {
  sales: any[];
  total: any[];
  invoices: any[];
};

export const Financial = () => {
  const items: LinkBreadCrump[] = [
    { label: "Dashboard", path: "/" },
    { label: "Meu financeiro", path: "/financial" },
    { label: "Visão geral", path: "" },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const [infos, setInfos] = useState<FinancialResponse>(
    {} as FinancialResponse
  );

  useEffect(() => {
    setLoading(true);
    api.get("client/app/financial").then((res) => setInfos(res.data));
    setLoading(false);
  }, []);

  return (
    <Container maxW={"container.xl"} mt={"4"} p={"4"}>
      <BreadcrumbPage items={items} />
      {loading ? (
        <Box w={"100%"} m={"8"} textAlign={"center"}>
          <Spinner size={"lg"} label="Buscando informações..." />
        </Box>
      ) : (
        <Box mt={"8"} mb={20}>
          <Box>
            <Tabs variant={"soft-rounded"}>
              <TabList>
                <Tab>
                  {" "}
                  <BellIcon mx={2} /> Compras em aberto
                </Tab>
                <Tab>
                  {" "}
                  <CheckIcon mx={2} /> Faturas geradas
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {infos.sales ? (
                    <FinancialOpens
                      total={infos.total[0].total}
                      sales={infos.sales}
                    />
                  ) : (
                    <Text> Nenhuma compra em aberto encontrada </Text>
                  )}
                </TabPanel>
                <TabPanel>
                  {infos.invoices ? (
                    <FinancialInvoices invoices={infos.invoices} />
                  ) : (
                    <Text> Nenhum faturamento encontrado </Text>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}
    </Container>
  );
};
