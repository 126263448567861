import { AddIcon, CheckIcon, InfoIcon } from "@chakra-ui/icons";
import { Heading, Box, Divider, Wrap, StatGroup } from "@chakra-ui/react";
import { ShortCut } from "./shortcut";

function QuickAccess() {
  return (
    <Box mt={"8"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Heading as={"h1"} fontSize={"lg"}>
          Acesso rápido
        </Heading>
      </Box>
      <Divider my={"4"} />
      <Box>
        <StatGroup>
          <ShortCut
            label="Novo pedido"
            path="/orders/new-order"
            bg="blue.400"
            color="white"
            icon={<AddIcon w={"6"} h={"6"} />}
          />
          <ShortCut
            label="Meu financeiro"
            path="/financial"
            bg="green.400"
            color="white"
            icon={<InfoIcon w={"6"} h={"6"} />}
          />
        </StatGroup>
      </Box>
    </Box>
  );
}

export default QuickAccess;
