import { CalendarIcon } from "@chakra-ui/icons";
import CurrencyFormat from "react-currency-format";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Stat, StatHelpText, StatLabel, StatNumber } from "@chakra-ui/react";
import { Link } from "react-router-dom";

type Pedido = {
  id: number;
  date: string;
  amount: string;
  status: string;
};

function PedidoStat(props: Pedido) {
  return (
    <Stat
      as={Link}
      to={`/orders/details/${props.id}`}
      shadow={"lg"}
      borderRadius={"4"}
      m={"2"}
      p={"4"}
    >
      <StatLabel textTransform={"uppercase"}>{props.status}</StatLabel>
      <StatNumber>
        <CurrencyFormat
          value={props.amount}
          prefix="R$ "
          displayType={"text"}
          fixedDecimalScale={true}
          decimalScale={2}
          decimalSeparator={","}
          thousandSeparator={"."}
        />
      </StatNumber>
      <StatHelpText>
        {" "}
        <CalendarIcon />{" "}
        {format(new Date(props.date), "d MMMM yyyy H:mm", {
          locale: ptBR,
        })}
      </StatHelpText>
    </Stat>
  );
}

export default PedidoStat;
