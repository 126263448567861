import { ArrowForwardIcon, CheckIcon } from "@chakra-ui/icons";
import { Container, Box, Spinner, Heading, Divider, Text, Button, FormControl, Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { BreadcrumbPage, LinkBreadCrump } from "../../components/Breadcrumb";
import NavBar from "../../components/Navbar";
import api from "../../services/api.service";

type AccountInfos = {
    username: string;
    email: string;
}

const Profile = () => {
    const items: LinkBreadCrump[] = [
        { label: "Dashboard", path: "/" },
        { label: "Minha conta", path: "/profile" }
    ];

    const [password, setPassword] = useState<string>('');
    const [account, setAccount] = useState<AccountInfos>();
    const [loading, setLoading] = useState<boolean>(false);
    const [btnLoading, setbtnLoading] = useState<boolean>(false);
    const toast = useToast();

    useEffect(() => {
        setLoading(true);
        api.get("auth/user").then((res) => {
            setAccount(res.data[0]);
            setLoading(false);
        });
    }, []);

    const handleUpdatePassword = () => {
        setbtnLoading(true)
        api.put('client/app/user/password', { password })
            .then(_ => {
                toast({
                    title: "Feito",
                    description: `Senha atualizada com sucesso`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setbtnLoading(false)
            }).catch((e) => {
                console.error(e)
                setbtnLoading(false)
            })
    }

    return (
        <>
            <NavBar />
            <Container maxW={"container.xl"} mt={"4"} p={"4"}>
                <BreadcrumbPage items={items} />
                {loading ? (
                    <Box w={"100%"} m={"8"} textAlign={"center"}>
                        <Spinner size={"lg"} label="Buscando seus dados..." />
                    </Box>
                ) : (
                    <Box mt={"8"} mb={20}>
                        <Box>
                            <Heading as={"h1"} fontSize={"lg"}>
                                Minha conta
                            </Heading>
                            <Divider my={"4"} />
                            <Text fontSize={"md"}>
                                Nome de usuário: {account?.username}
                            </Text>
                            <Text fontSize={"md"}>
                                E-mail: {account?.email}
                            </Text>
                        </Box>
                        <Box mt={'4'}>
                            <Heading as={"h2"} fontSize={"md"} fontWeight={'bold'}>
                                Trocar senha:
                            </Heading>
                            <FormControl mt={4}>
                                <Input id='password' placeholder="Digite aqui uma nova senha" type='password' minLength={6} onChange={(e) => setPassword(e.target.value)} />
                            </FormControl>
                            <Button
                                disabled={!password || password?.length < 6}
                                w={'100%'}
                                mt={2}
                                isLoading={btnLoading}
                                onClick={handleUpdatePassword}
                                colorScheme={"blue"}
                                rightIcon={<CheckIcon />}
                            >
                                Atualizar minha senha
                            </Button>
                        </Box>
                    </Box>
                )}
            </Container>
        </>
    );
};

export default Profile;