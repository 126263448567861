import {
  Container,
  Box,
  Spinner,
  Heading,
  Divider,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Badge,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QRcode from "react-qr-code";
import { LinkBreadCrump, BreadcrumbPage } from "../../../components/Breadcrumb";
import api from "../../../services/api.service";
import CurrencyFormat from "react-currency-format";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { CalendarIcon } from "@chakra-ui/icons";

type Order = {
  id: string;
  status: string;
  total: number;
  code: string;
  created_at: string;
  updated_at: string;
  scheduled_at: string;
  products: object[];
  user: any;
  event: any;
};

export const DetailsOrder = () => {
  let { id } = useParams<"id">();
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>({} as Order);
  const items: LinkBreadCrump[] = [
    { label: "Dashboard", path: "/" },
    { label: "Pedidos", path: "/orders" },
    { label: "Detalhes do pedido", path: `/orders/details/${id}` },
  ];

  let totalAmountOrder = 0;

  if (order?.products?.length) {
    const amounts = order.products?.map((item: any) => {
      return item.amount;
    });

    totalAmountOrder = amounts?.reduce((sum: number, amt: number) => {
      return sum + amt;
    });
  }

  useEffect(() => {
    setLoading(true);
    api.get(`client/app/orders/${id}`).then((res) => setOrder(res.data));
    setLoading(false);
  }, [id]);

  return (
    <Container maxW={"container.xl"} mt={"4"} p={"4"}>
      <BreadcrumbPage items={items} />
      {loading ? (
        <Box w={"100%"} m={"8"} textAlign={"center"}>
          <Spinner size={"lg"} label="Buscando produtos..." />
        </Box>
      ) : (
        <Box mt={"8"} mb={20}>
          <Box>
            <Heading as={"h2"} fontSize={"md"}>
              Pedido # {order.id}
            </Heading>
            <Divider my={"4"} />
            <Box>
              <Badge
                colorScheme={"green"}
                fontSize={"md"}
                textTransform={"uppercase"}
              >
                {order.status}
              </Badge>
              <Badge mx={2} fontSize={"md"}>
                Total: <CurrencyFormat
                  value={order?.total}
                  prefix="R$ "
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </Badge>
            </Box>

            <Box textAlign={"center"} mt={10}>
              {order?.code ? (
                <>
                  <QRcode
                    style={{ margin: "0 auto" }}
                    value={order.id}
                    size={180}
                  />
                  <Text mt={"8"}>Informe o código para o entregador:</Text>
                  <Text fontSize={"xx-large"} fontFamily={"monospace"}>
                    {order.code}
                  </Text>
                </>
              ) : (
                ""
              )}
            </Box>
            <Box mt={"8"} mb={20}>
              <Box>
                <Heading as={"h1"} fontSize={"lg"}>
                  Lista de produtos do pedido
                </Heading>
                <Divider my={"4"} />
                <Table size={"sm"}>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>Produto</Th>
                      <Th>Und. Medida</Th>
                      <Th>Qtd.</Th>
                      <Th>Valor Unit.</Th>
                      <Th>Subtotal</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {order?.products?.length ? (
                      order?.products.map((item: any) => (
                        <Tr key={item.id}>
                          <Td>{item.id}</Td>
                          <Td>{item.product.label}</Td>
                          <Td textTransform={"uppercase"}>
                            {item.product.measure}
                          </Td>
                          <Td>{item.amount}</Td>
                          <Td>
                            <CurrencyFormat
                              value={item.price}
                              prefix="R$ "
                              displayType={"text"}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              decimalSeparator={","}
                              thousandSeparator={"."}
                            />
                          </Td>
                          <Td>
                            <CurrencyFormat
                              value={item.total}
                              prefix="R$ "
                              displayType={"text"}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              decimalSeparator={","}
                              thousandSeparator={"."}
                            />
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </Tbody>
                </Table>
              </Box>
              <Box my={8}>
                {order?.scheduled_at ? (
                  <Text fontSize={"md"}>Expectativa de recebimento: <CalendarIcon />{" "}
                    {format(new Date(order?.scheduled_at), "d MMMM yyyy H:mm", {
                      locale: ptBR,
                    })}</Text>
                ) : <></>}
                <Text fontSize={"md"}>Produtos selecionados: {order?.products?.length} </Text>
                <Text fontSize={"md"}>
                  Quantidade total de itens no pedido: {totalAmountOrder}
                </Text>
                <Text fontSize={"md"}>Local de entrega: {order?.event?.name}</Text>
                <Text fontSize={"md"}>Endereço de entrega: {order?.event?.address}</Text>
                <Text fontSize={"md"} textDecoration={'underline'}>Realizado por: {order?.user?.username}</Text>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};
