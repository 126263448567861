import { RepeatClockIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Container,
  Box,
  Heading,
  Text,
  Divider,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import { LinkBreadCrump, BreadcrumbPage } from "../../../components/Breadcrumb";
import api from "../../../services/api.service";

export const ListOrders = () => {
  const items: LinkBreadCrump[] = [
    { label: "Dashboard", path: "/" },
    { label: "Pedidos", path: "/orders" },
  ];

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    api.get("client/app/orders").then((res) => setOrders(res.data));
    setLoading(false);
  }, []);

  return (
    <Container maxW={"container.xl"} mt={"4"} p={"4"}>
      <BreadcrumbPage items={items} />
      {loading ? (
        <Box w={"100%"} m={"8"} textAlign={"center"}>
          <Spinner size={"lg"} label="Buscando produtos..." />
        </Box>
      ) : (
        <Box mt={"8"} mb={20}>
          <Box>
            <Heading as={"h1"} fontSize={"lg"}>
              Meus pedidos
            </Heading>
            <Divider my={"4"} />
            <Table size={"sm"}>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Feito por</Th>
                  <Th>Total</Th>
                  <Th>Status</Th>
                  <Th>Qtd de Itens</Th>
                  <Th>Data</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {orders.length ? (
                  orders.map((item: any, index) => (
                    <Tr key={index}>
                      <Td>
                        {item.status !== "aguardando confirmação" ? (
                          <Link to={`/orders/details/${item.id}`} title="Visualizar detalhes do pedido">
                            <Text colorScheme={'blue'}>
                              {item.id}
                            </Text>
                          </Link>
                        ) : item.id}
                      </Td>
                      <Td>{item.user?.username}</Td>
                      <Td>
                        <CurrencyFormat
                          value={item.total}
                          prefix="R$ "
                          displayType={"text"}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                        />
                      </Td>
                      <Td textTransform={"uppercase"}>{item.status}</Td>
                      <Td>{item.products?.length}</Td>
                      <Td>
                        {format(new Date(item.created_at), "d MMMM yyyy H:mm", {
                          locale: ptBR,
                        })}
                      </Td>
                      <Td width={"min-content"}>
                        {item.status !== "aguardando confirmação" ? (
                          <Link to={`/orders/details/${item.id}`} title="Visualizar detalhes do pedido">
                            <SearchIcon />
                          </Link>
                        ) : (
                          <RepeatClockIcon color={"yellow.500"} />
                        )}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <></>
                )}
              </Tbody>
            </Table>
          </Box>
        </Box>
      )}
    </Container>
  );
};
