import { useToast } from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";
import { ItemToOrder } from "../components/Product/item";
import api from "../services/api.service";

interface OrderProviderData {
  items: ItemToOrder[] | null;
  addItemToOrder(item: ItemToOrder): void;
  removeItemOrder(item: ItemToOrder): void;
  submitOrder(event: number, scheduledAt: string | null): void;
}

const OrderContext = createContext<OrderProviderData>({} as OrderProviderData);

export const OrderProvider = ({ children }: any) => {
  const [items, setItems] = useState<ItemToOrder[]>([]);
  const toast = useToast();

  function addItemToOrder(item: ItemToOrder): void {
    setItems(() => [...items, item]);
    toast({
      title: "Feito",
      description: `${item.amount} ${item.measure} do item ${item.label} foram adicionados ao seu pedido!`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }

  function removeItemOrder(item: ItemToOrder): void {
    const res = items.filter((i: ItemToOrder) => {
      return i.id !== item.id;
    });

    setItems(res);

    toast({
      title: `Item # ${item.id}  removido do pedido`,
      status: "warning",
      duration: 5000,
      isClosable: true,
    });
  }

  function submitOrder(event: number, scheduledAt: string | null): void {
    api
      .post("client/app/orders", { items, event, scheduledAt })
      .then((res) => {
        setItems([]);
        toast({
          title: "Pronto!",
          description: `Pedido recebido com sucesso! Ao receber os itens informe o código ${res.data.code} ao motorista/entregador`,
          status: "success",
          duration: 8000,
          isClosable: true,
        });
      })
      .catch((err) => console.error(err));
  }

  return (
    <OrderContext.Provider
      value={{ items, addItemToOrder, removeItemOrder, submitOrder }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export function useOrder() {
  const context = useContext(OrderContext);
  return context;
}

export default OrderContext;
