import { Box, Stat, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

type ShortCutButton = {
  label: string;
  icon: any;
  path: string;
  bg?: string;
  color?: string;
};

export const ShortCut = (props: ShortCutButton) => {
  return (
    <Stat
      as={Link}
      to={props.path}
      bg={props.bg}
      p={4}
      borderRadius={"4"}
      m={"2"}
      color={props.color}
      textAlign={"center"}
      shadow={"lg"}
    >
      {props.icon}
      <Text mt={"2"} fontSize={"lg"}>
        {props.label}
      </Text>
    </Stat>
  );
};
