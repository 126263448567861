import { CheckIcon, DeleteIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Container,
  Heading,
  Box,
  Divider,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Link,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Select,
  FormControl,
  Tooltip,
  Input,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import { Navigate } from "react-router-dom";
import { BreadcrumbPage, LinkBreadCrump } from "../../../components/Breadcrumb";
import NewEventForm from "../../../components/Events/new-event";
import { ItemToOrder } from "../../../components/Product/item";
import { useOrder } from "../../../contexts/order";
import api from "../../../services/api.service";

type ClientEvent = {
  id: number;
  client_id: number;
  name: string;
  address: string;
};

const ReviewOrder = () => {
  const items: LinkBreadCrump[] = [
    { label: "Dashboard", path: "/" },
    { label: "Pedidos", path: "/orders" },
    { label: "Novo pedido", path: "/orders/new-order" },
    { label: "Revisão do pedido", path: "/orders/review-order" },
  ];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [events, setEvents] = useState<ClientEvent[]>([]);
  const [newEvent, setNewEvent] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<number>(0);
  const [scheduledAt, setscheduledAt] = useState<string | null>(null);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef() as React.MutableRefObject<any>;
  const [itemRemove, setItemRemove] = useState<ItemToOrder>({} as ItemToOrder);
  const date = new Date().toISOString().split('T')[0];
  const time = new Date().toISOString().split('T')[1].split('.')[0];


  useEffect(() => {
    getEventsFromAPI()
  }, []);

  const getEventsFromAPI = () => {
    setNewEvent(false)
    api.get("client/app/events").then((res) => setEvents(res.data.events));
  }

  const handleRemoveItemClicked = (item: ItemToOrder): void => {
    setItemRemove(item);
    setIsOpen(true);
  };

  const checkSelectedEvent = (e: number) => {
    if (e) {
      setSelectedEvent(e);
    } else {
      setNewEvent(true)
    }
  }

  const cancelNewEvent = () => {
    setNewEvent(false)
  }

  const handleFinishClicked = (): void => {
    order.submitOrder(selectedEvent, scheduledAt);
  };

  const order = useOrder();

  let totalOrder = 0;
  let totalAmountOrder = 0;

  if (order.items?.length) {
    const subTotals = order.items?.map((item: ItemToOrder) => {
      return item.price * item.amount;
    });

    const amounts = order.items?.map((item: ItemToOrder) => {
      return item.amount;
    });

    totalOrder = subTotals?.reduce((sum, sub) => {
      return sum + sub;
    });

    totalAmountOrder = amounts?.reduce((sum, amt) => {
      return sum + amt;
    });
  }

  return (
    <Container maxW={"container.xl"} mt={"4"} p={"4"}>
      <BreadcrumbPage items={items} />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Confirmar exclusão de item</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Deseja mesmo remover {itemRemove.label} do seu pedido?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose}>Não</Button>
            <Button
              onClick={() => {
                order.removeItemOrder(itemRemove);
                onClose();
              }}
              colorScheme="red"
              ml={3}
            >
              Sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Box mt={"8"} mb={20}>
        <Box>
          <Heading as={"h1"} fontSize={"lg"}>
            Lista de produtos adicionados ao pedido
          </Heading>
          <Divider my={"4"} />
          <Table size={"sm"}>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Produto</Th>
                <Th>Und. Medida</Th>
                <Th>Qtd.</Th>
                <Th>Valor Unit.</Th>
                <Th>Subtotal</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {order.items?.length ? (
                order.items.map((item: ItemToOrder) => (
                  <Tr key={item.id}>
                    <Td>{item.id}</Td>
                    <Td>{item.label}</Td>
                    <Td textTransform={"uppercase"}>{item.measure}</Td>
                    <Td>{item.amount}</Td>
                    <Td>
                      <CurrencyFormat
                        value={item.price}
                        prefix="R$ "
                        displayType={"text"}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                      />
                    </Td>
                    <Td>
                      <CurrencyFormat
                        value={item.price * item.amount}
                        prefix="R$ "
                        displayType={"text"}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                      />
                    </Td>
                    <Td width={"min-content"}>
                      <Link
                        color={"red.500"}
                        onClick={() => {
                          handleRemoveItemClicked(item);
                        }}
                      >
                        <DeleteIcon /> excluir
                      </Link>
                    </Td>
                  </Tr>
                ))
              ) : (
                <></>
              )}
            </Tbody>
          </Table>
        </Box>
        {order.items?.length ? (
          <>
            <Box textAlign={"right"} my={8}>
              <Text>
                Valor total do pedido:{" "}
                <CurrencyFormat
                  value={totalOrder}
                  style={{ fontSize: "1.4rem" }}
                  prefix="R$ "
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </Text>
              <Text>Produtos selecionados: {order.items?.length} </Text>
              <Text>
                Quantidade total de itens no pedido: {totalAmountOrder}
              </Text>
            </Box>
            {newEvent ? (
              <NewEventForm onCancel={cancelNewEvent} onSuccess={getEventsFromAPI} />
            ) :
              <Box>
                <Box w={"100%"} mr={4}>
                  <Text mb={2}>Quando entregar? (opcional)
                    <Tooltip label="Não informando a expectavida de recebimento do pedido, a data e horário será atribuída pelo atendente">
                      <InfoIcon ml={2} />
                    </Tooltip>
                  </Text>
                  <FormControl>
                    <Input id='datetime' min={`${date} ${time}`} type='datetime-local' onChange={(e) => setscheduledAt(e.target.value)} />
                  </FormControl>
                </Box>
                <Box w={"100%"} mt={4}>
                  <Text mb={2}>Onde entregar?</Text>
                  <Select
                    onChange={(e) => {
                      checkSelectedEvent(parseInt(e.target.value));
                    }}
                    placeholder="Escolher..."
                  >
                    <optgroup label="NOVO">
                      <option value="0">Adicionar um novo local</option>
                    </optgroup>
                    <optgroup label="MEUS LOCAIS">
                      {events &&
                        events.map((event: ClientEvent) => {
                          return (
                            <option
                              key={event.id}
                              value={event.id}
                            >{`${event.name} - ${event.address}`}</option>
                          );
                        })}
                    </optgroup>
                  </Select>
                </Box>

                <Box mt={4}>
                  <Button
                    disabled={!selectedEvent}
                    w={'100%'}
                    onClick={handleFinishClicked}
                    colorScheme={"green"}
                    rightIcon={<CheckIcon />}
                  >
                    Finalizar pedido
                  </Button>
                </Box>
              </Box>
            }

          </>
        ) : (
          <Navigate to={"/orders/new-order"} />
        )}
      </Box>
    </Container >
  );
};

export default ReviewOrder;
