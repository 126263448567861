import { Outlet, Routes, Route } from "react-router-dom";
import { Financial } from ".";
import NavBar from "../../components/Navbar";
import { FinancialInvoiceDetails } from "./Invoices/details";

export const FinancialRoutes = () => {
  return (
    <>
      <NavBar />
      <Outlet />
      <Routes>
        <Route path="/" element={<Financial />} />
        <Route
          path="/invoice/details/:id"
          element={<FinancialInvoiceDetails />}
        />
      </Routes>
    </>
  );
};
