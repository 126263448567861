import {
  Avatar,
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Menu,
  MenuButton,
  Image,
  MenuItem,
  Text,
  MenuList,
  useDisclosure,
  Button,
  useMediaQuery,
  DrawerHeader,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, SettingsIcon } from "@chakra-ui/icons";
import NavMenuItem from "./nav-menu-item";
import { useAuth } from "../../contexts/auth";
import logo from "../../assets/images/logo.png";
import "./style.css"
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSmallScreen] = useMediaQuery("(max-width: 768px)");
  const { Logout, user } = useAuth();

  async function handleLogout() {
    Logout();
  }

  return (
    <Container id="navbar" maxW={"container.xl"} mt={"4"}>
      <Box bg={"gray.50"} shadow={"lg"} p={"4"} borderRadius={"8"}>
        <HStack justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <Button m={"0"} bg={"transparent"} onClick={onOpen}>
              <HamburgerIcon w={"6"} h={"6"} />
            </Button>
            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent bg={"#170389"}>
                <DrawerHeader>
                  <Box marginBottom={"4"}>
                    <Image src={logo} w={"40"} mx={"auto"} />
                  </Box>
                </DrawerHeader>
                <DrawerCloseButton color={"white"} />
                <DrawerBody color={"white"}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <NavMenuItem label="Meu dashboard" path="/" />
                    <NavMenuItem label="Pedidos" path="/orders/" />
                    <NavMenuItem label="Receber pedido" path="/" />
                    <NavMenuItem label="Financeiro" path="/financial" />
                    <NavMenuItem label="Relatórios" path="/" />
                  </Box>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
            <Heading hidden={isSmallScreen} as={"h2"} ml={"4"} size={"sm"}>
              GELO ICEBERG - CLIENTE
            </Heading>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Menu>
              <Text fontSize={"sm"} color={"gray.400"} mx={"4"}>
                {user?.email}
              </Text>
              <MenuButton>
                <Avatar name={user?.name} size={"sm"} />
              </MenuButton>
              <MenuList fontSize={"sm"}>
                <Link to={'/profile'}>
                  <MenuItem icon={<SettingsIcon />}> Minha conta</MenuItem>
                </Link>
                <MenuItem onClick={handleLogout} icon={<CloseIcon />}>
                  {" "}
                  Encerrar sessão
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </HStack>
      </Box>
    </Container>
  );
}

export default NavBar;
