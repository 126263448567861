import { ChevronRightIcon } from "@chakra-ui/icons";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";

type BreadCrumpProps = {
  items: LinkBreadCrump[];
};

export type LinkBreadCrump = {
  path: string;
  label: string;
};

export const BreadcrumbPage = (props: BreadCrumpProps) => {
  return (
    <Breadcrumb spacing={"2"} separator={<ChevronRightIcon />}>
      {props.items.map((item: LinkBreadCrump, index) => {
        return (
          <BreadcrumbItem key={index}>
            <BreadcrumbLink as={Link} to={item.path}>
              {item.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};
