import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider, RequireAuth } from "./contexts/auth";
import { FinancialRoutes } from "./pages/Financial/routes";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { Orders } from "./pages/Orders/routes";
import Profile from "./pages/Profile";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route path="login" element={<Login />} />
          <Route path="profile" element={<Profile />} />
          <Route
            path="orders/*"
            element={
              <RequireAuth>
                <Orders />
              </RequireAuth>
            }
          />
          <Route
            path="financial/*"
            element={
              <RequireAuth>
                <FinancialRoutes />
              </RequireAuth>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
