import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import CurrencyFormat from "react-currency-format";
import api from "../../../services/api.service";
import "./style.css";

type InvoiceResponse = {
  invoice: any;
  summary: any;
};

export const FinancialInvoiceDetails = () => {
  let { id } = useParams<"id">();
  const [data, setData] = useState<InvoiceResponse>({} as InvoiceResponse);

  useEffect(() => {
    api.get(`client/app/financial/invoice/${id}`).then((res) => setData(res.data));
  }, [id]);

  const getTotal = () => {
    return data.invoice.cashier.reduce((prev: any, item: any) => {
      return parseFloat(prev) + parseFloat(item.total);
    }, 0);
  };

  const getTotalPaid = () => {
    return data.invoice.receipt.reduce((prev: any, item: any) => {
      return parseFloat(prev) + parseFloat(item.total);
    }, 0);
  };

  return (
    <>
      {data.invoice ? (
        <div className="print" style={{ width: "100%" }}>
          <div className="header" style={{ width: "100%" }}>
            <table width={"100%"}>
              <tr>
                <td>
                  <h1 className="nopadding">GELO ICEBERG</h1>
                  <p className="nopadding">
                    IGOR L S ALVES | CNPJ: 14.426.651/0001-52 | I.E.:
                    12.369.473-6
                  </p>
                  <p className="nopadding">
                    RUA 07, Nº 05, SÃO FRANCISCO | SÃO LUÍS - MA | (98)
                    98820-7557
                  </p>
                </td>
              </tr>
            </table>
            <h1>FATURA # {data.invoice.id}</h1>
          </div>
          <h4>INFORMAÇÕES</h4>
          <table className="client" width={"100%"}>
            <tr>
              <td width="26%">Cliente:</td>
              <td>
                {data.invoice.client.name} ({data.invoice.client.document})
              </td>
            </tr>
            <tr>
              <td>Período de faturamento:</td>
              <td>
                {format(new Date(data.invoice.start), "d MMMM yyyy", {
                  locale: ptBR,
                })}{" "}
                -{" "}
                {format(new Date(data.invoice.end), "d MMMM yyyy", {
                  locale: ptBR,
                })}
              </td>
            </tr>
            <tr>
              <td>Total da fatura:</td>
              <td>
                <CurrencyFormat
                  value={data.invoice.total}
                  prefix="R$ "
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              </td>
            </tr>
            <tr>
              <td>Situação atual:</td>
              <td>
                <strong>{data.invoice.status}</strong>
              </td>
            </tr>
            <tr>
              <td>Faturado em:</td>
              <td>
                {format(new Date(data.invoice.created_at), "d MMMM yyyy", {
                  locale: ptBR,
                })}
              </td>
            </tr>
            <tr>
              <td>Última atualização:</td>
              <td>
                {format(new Date(data.invoice.updated_at), "d MMMM yyyy", {
                  locale: ptBR,
                })}
              </td>
            </tr>
          </table>

          <h4>RESUMO DE OPERAÇÕES</h4>
          <table width={"100%"}>
            <thead>
              <tr>
                <th>#</th>
                <th>Operador</th>
                <th>Retirada por</th>
                <th>Valor</th>
                <th>Forma</th>
                <th>Situação</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {data.invoice.cashier.map((i: any) => (
                <tr>
                  <td>{i.id}</td>
                  <td>{i.user.username}</td>
                  <td>{i.collaborator}</td>
                  <td>
                    <CurrencyFormat
                      value={i.total}
                      prefix="R$ "
                      displayType={"text"}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                    />
                  </td>
                  <td>{i.payment}</td>
                  <td>{i.status}</td>
                  <td>
                    {format(new Date(i.real_date), "d MMMM yyyy", {
                      locale: ptBR,
                    })}
                  </td>
                </tr>
              ))}

              <tr>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>
                  <CurrencyFormat
                    value={getTotal()}
                    prefix="R$ "
                    displayType={"text"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <h4>DETALHAMENTO DOS PEDIDOS</h4>
          <table width={"100%"}>
            <thead>
              <tr>
                <th>#</th>
                <th>Feito em</th>
                <th>Evento</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.invoice.cashier.map((i: any) => (
                <tr>
                  <td>{i.id}</td>
                  <td>
                    {format(new Date(i.real_date), "d MMMM yyyy", {
                      locale: ptBR,
                    })}
                  </td>
                  <td>- evento - </td>
                  <td>
                    <CurrencyFormat
                      value={i.total}
                      prefix="R$ "
                      displayType={"text"}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                    />
                  </td>
                  <td>
                    <table width="100%">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Qtde.</th>
                          <th>Unit.</th>
                          <th>Subt.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {i.items.map((p: any) => (
                          <tr>
                            <td width="40%">{p.products.label}</td>
                            <td width="10%">{p.amount}</td>
                            <td width="25%">
                              <CurrencyFormat
                                value={p.price}
                                prefix="R$ "
                                displayType={"text"}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                decimalSeparator={","}
                                thousandSeparator={"."}
                              />
                            </td>
                            <td width="25%">
                              <CurrencyFormat
                                value={p.total}
                                prefix="R$ "
                                displayType={"text"}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                decimalSeparator={","}
                                thousandSeparator={"."}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <h4>RESUMO</h4>
          <table width={"100%"}>
            <thead>
              <tr>
                <th>Produto</th>
                <th>Qtd.</th>
                <th>Unitário</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {data.summary.product.map((p: any) => (
                <tr>
                  <td>{p.label}</td>
                  <td>{p.totalAmount}</td>
                  <td>
                    <CurrencyFormat
                      value={p.price}
                      prefix="R$ "
                      displayType={"text"}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={p.subtotal}
                      prefix="R$ "
                      displayType={"text"}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td>(=) Total</td>
                <td>a</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>(-) Recebido</td>
                <td>a</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>(=) Saldo devedor</td>
                <td>a</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <table width={"100%"}>
            <thead>
              <tr>
                <th>Evento</th>
                <th>Qtd.</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>a</td>
                <td>a</td>
                <td>a</td>
              </tr>
            </tbody>
          </table>

          <h4>PAGAMENTOS</h4>
          <table width={"100%"}>
            <thead>
              <tr>
                <th>#</th>
                <th>Valor</th>
                <th>Forma</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {data.invoice.receipt.map((r: any) => (
                <tr>
                  <td>{r.id}</td>
                  <td>
                    <CurrencyFormat
                      value={r.total}
                      prefix="R$ "
                      displayType={"text"}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                    />
                  </td>
                  <td>{r.method}</td>
                  <td>
                    {format(new Date(r.created_at), "d MMMM yyyy", {
                      locale: ptBR,
                    })}
                  </td>
                </tr>
              ))}
              <tr>
                <td>Total</td>
                <td>
                  <CurrencyFormat
                    value={getTotalPaid()}
                    prefix="R$ "
                    displayType={"text"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                  />
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <h3>
          Não foi possível visualizar as informações da fatura selecionada
        </h3>
      )}
    </>
  );
};
