import { ArrowForwardIcon, CheckIcon } from "@chakra-ui/icons";
import { toast, Flex, Box, FormControl, FormLabel, Input, FormErrorMessage, Button, useToast } from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import React, { useEffect } from "react";
import api from "../../services/api.service";
import requiredField from "../../utils/validations/required";

type FormProps = {
    onCancel: Function
    onSuccess: Function
}

const NewEventForm = (propsF: FormProps) => {
    const toast = useToast();
    return (
        <Formik
            initialValues={{ name: "", address: "", phone: "" }}
            onSubmit={(values, actions) => {
                setTimeout(() => {
                    actions.setSubmitting(false);
                    api.post('client/app/events', values).then(_ => {
                        toast({
                            title: "Novo local de entrega cadastrado",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                        propsF.onSuccess()
                    }).catch((err) => {
                        actions.setSubmitting(false);
                        toast({
                            title: "Ocorreu um problema no registro do novo local",
                            description: "Verifique os dados informados e tente novamente",
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                        });
                        console.error(err);
                    })
                }, 1000);
            }}
        >
            {(props) => (
                <Box>
                    <Form>
                        <Field name="name" validate={requiredField}>
                            {({ field, form }: any) => (
                                <FormControl
                                    isRequired
                                    marginBottom={"4"}
                                    isInvalid={form.errors.name && form.touched.name}
                                >
                                    <FormLabel htmlFor="name">Nome do local/evento:</FormLabel>
                                    <Input
                                        {...field}
                                        id="name"
                                        placeholder="Ex.: Samba Semanal na Praia"
                                    />
                                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                        <Field name="address" validate={requiredField}>
                            {({ field, form }: any) => (
                                <FormControl
                                    isRequired
                                    marginBottom={"4"}
                                    isInvalid={form.errors.address && form.touched.address}
                                >
                                    <FormLabel htmlFor="address">Endereço:</FormLabel>
                                    <Input
                                        {...field}
                                        id="address"
                                        placeholder="Informe o endereço do local"
                                    />
                                    <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                        <Field name="phone" validate={requiredField}>
                            {({ field, form }: any) => (
                                <FormControl
                                    isRequired
                                    marginBottom={"4"}
                                    isInvalid={form.errors.phone && form.touched.phone}
                                >
                                    <FormLabel htmlFor="phone">Telefone:</FormLabel>
                                    <Input
                                        {...field}
                                        id="phone"
                                        placeholder="Informe um telefone de contato do local"
                                    />
                                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Button
                            rightIcon={<CheckIcon />}
                            type="submit"
                            isLoading={props.isSubmitting}
                            w={"100%"}
                            mt={"4"}
                        >
                            Registrar novo local
                        </Button>
                        <Button
                            variant='link'
                            onClick={() => propsF.onCancel()}
                            w={"100%"}
                            mt={"4"}
                        >
                            cancelar
                        </Button>
                    </Form>
                </Box>
            )}
        </Formik>
    )
}

export default NewEventForm