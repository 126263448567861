import { AddIcon } from "@chakra-ui/icons";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Box,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useOrder } from "../../contexts/order";

export type ProductItemProps = {
  id: number;
  label: string;
  price: number;
  measure: string;
};

export type ItemToOrder = ProductItemProps & {
  amount: number;
};

export const ProductItem = (props: ProductItemProps) => {
  const [amount, setAmount] = useState<number>(1);
  const order = useOrder();

  const handleAddItem = () => {
    const item: ItemToOrder = {
      ...props,
      amount,
    };
    order.addItemToOrder(item);
  };
  return (
    <Stat shadow={"lg"} minW={"sm"} borderRadius={"4"} m={"2"} p={"4"}>
      <StatLabel>{props.label}</StatLabel>
      <StatNumber>
        <CurrencyFormat
          value={props.price}
          prefix="R$ "
          displayType={"text"}
          fixedDecimalScale={true}
          decimalScale={2}
          decimalSeparator={","}
          thousandSeparator={"."}
        />
      </StatNumber>
      <StatHelpText textTransform={"uppercase"}>{props.measure}</StatHelpText>
      <Box display={"flex"} justifyContent={"space-between"}>
        <NumberInput
          defaultValue={1}
          min={1}
          w={"40"}
          onChange={(valueString) => setAmount(parseInt(valueString))}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Button onClick={handleAddItem} w={"40"} leftIcon={<AddIcon />}>
          Adicionar
        </Button>
      </Box>
    </Stat>
  );
};
